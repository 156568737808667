import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Header, Segment } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import LoginForm from "../users/LoginForm";

export default observer(function HomePage() {
  const { userStore, modalStore } = useStore();

  useEffect(() => {
    const getUser = async () => {
      await userStore.getLoggedIsUser();
    };

    getUser();
  }, [userStore.token]);

  return (
    <Segment inverted textAlign="center" vertical className="masthead">
      <Container
        fluid
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Header as="h1" inverted style={{ marginBottom: 0 }}>
          VAST
        </Header>
        <Header as="h1" inverted style={{ marginTop: 0 }}>
          AI Models
        </Header>
        {userStore.isLoggedIn ? (
          <>
            <Header
              as="h2"
              inverted
              content={`Welcome back ${userStore.user?.displayName}`}
            />
            <Button as={Link} to="/chatbot" size="huge" inverted>
              Go to Ai models!
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => modalStore.openModal(<LoginForm />)}
              size="huge"
              inverted
            >
              Login!
            </Button>
            {/* <Button href="https://myapps.microsoft.com/signin/c76ce15c-af91-4c15-85a4-667f00984fc9?tenantId=ecaa386b-c8df-4ce0-ad01-740cbdb5ba55" size='huge' inverted>
                            Login!
                        </Button> */}
            {/* <Button onClick={() => modalStore.openModal(<RegsiterForm />)} size='huge' inverted>
                            Register
                        </Button> */}
          </>
        )}
      </Container>
    </Segment>
  );
});
