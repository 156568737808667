import { FaChevronLeft, FaPaperPlane } from "react-icons/fa";
import { PromptHeader } from "./PromptHeader";
import { PromptCard } from "./PromptCard";
import { FC, useEffect, useRef, useState } from "react";
import { Button, Input, Space } from "antd";

type Props = {
  prompts: any[];
  promptDescription: string;
  onPromptClick: (val?: string) => void;
  generatePrompt: (val: string) => void;
};

export const ChatPromptHelper: FC<Props> = ({
  prompts,
  promptDescription,
  onPromptClick,
  generatePrompt,
}) => {
  console.log(prompts);

  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [showHelperDialog, setShowHelperDialog] = useState(false);

  const onButtonClick = async () => {
    if (!value) {
      return null;
    }
    setLoading(true);
    await generatePrompt(value);
    setValue("");
    setLoading(false);
  };

  // inactivity
  let timeout = useRef<NodeJS.Timeout | null>(null);

  const restartInactivity = () => {
    setShowHelperDialog(false);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setShowHelperDialog(true);
    }, 1000 * 2);
  };

  useEffect(() => {
    restartInactivity();

    // listen for mouse events
    window.addEventListener("mousemove", restartInactivity);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        window.removeEventListener("mousemove", restartInactivity);
      }
    };
  }, []);

  return (
    <div
      className={`relative hidden lg:flex justify-between items-start bg-white ml-5 shadow-lg rounded-lg duration-300 ${
        !show && "max-w-[4rem]"
      }`}
    >
      <div
        className={`${
          !show && showHelperDialog ? "lg:flex animate-bounce" : "invisible"
        } need-help-dialog absolute bg-basf-blue text-white font-bold top-3 -left-[8.5rem] shadow-xl p-4 rounded-full duration-200`}
      >
        Need Help?
      </div>

      <div>
        <div
          className={`bg-white shadow border rounded p-4 hover:cursor-pointer m-2`}
          onClick={() => setShow(!show)}
        >
          <FaChevronLeft
            className={`${show ? "rotate-180" : "rotate-0"} duration-300`}
          />
        </div>
      </div>

      <div
        className={`${
          show ? "flex" : "invisible translate-x-full"
        } max-w-lg duration-300 h-full`}
      >
        <aside
          className={`duration-400 min-w-lg max-w-lg flex-none flex-col hidden bg-blue-100 border ${
            show && "md:flex"
          }`}
        >
          <PromptHeader
            title="Prompt Helper"
            subtitle="(Click on generated prompt to use)"
          />

          <div
            className={`flex-1 overflow-auto px-4 pt-4 ${
              prompts.length === 0 ? "items-center justify-center" : ""
            } flex flex-col`}
          >
            {prompts.length > 0 ? (
              prompts.map((prompt, i) =>
                prompt ? (
                  <PromptCard
                    key={i}
                    prompt={prompt}
                    onSubmit={onPromptClick}
                  />
                ) : null
              )
            ) : (
              <div className="text-slate-400 text-center text-lg font-light px-10">
                {promptDescription}
              </div>
            )}
          </div>

          <div className="p-5 border-t border-blue-200 bg-white">
            <Space.Compact block size="large">
              <Input
                placeholder="Write your keywords here..."
                className="message-input"
                onChange={(e) => {
                  setValue(e.currentTarget?.value);
                }}
                value={value}
              />
              <Button
                className="message-button"
                disabled={loading}
                loading={loading}
                onClick={onButtonClick}
              >
                Submit
              </Button>
            </Space.Compact>
          </div>
        </aside>
      </div>
    </div>
  );
};
