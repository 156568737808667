import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ModalContainer from "../common/modals/ModalContainer";
import LoadingComponent from "./LoadingComponent";
import { useStore } from "../stores/store";
import NavBar from "./NewNavBar";
import HomePage from "../../features/home/HomePage";

function App() {
  const location = useLocation();
  const { commonStore, userStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore]);

  if (!commonStore.appLoaded)
    return <LoadingComponent content="Loading app..." />;

  return (
    <div className="font-sans flex flex-col flex-1 h-screen bg-stone-200">
      <ScrollRestoration />
      <ModalContainer />
      <ToastContainer position="bottom-right" hideProgressBar theme="colored" />
      {location.pathname === "/" ? (
        <HomePage />
      ) : (
        <>
          <NavBar />
          <Outlet />
        </>
      )}
    </div>
  );
}

export default observer(App);
