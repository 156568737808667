import { observer } from "mobx-react-lite";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";

import { AuthLayout } from "./components/AuthLayout";
import { AuthWarning } from "./components/AuthWarning";
import { useStore } from "../../app/stores/store";

export default observer(function RegisterPage() {
  const { userStore } = useStore();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const registerHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await userStore.userRegister({
        fullName: name,
        username,
        email,
        password,
      });
      toast.success(
        "Register successfully, waiting for admin to verify your account.",
      );
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout>
      <div className="bg-white max-w-md p-4 rounded shadow-lg flex flex-col">
        <AuthWarning />

        <h1 className="text-center text-3xl font-bold my-4">Create Account</h1>
        <form className="flex flex-col gap-y-3" onSubmit={registerHandler}>
          <div className="space-y-2">
            <label>Name</label>
            <Input
              placeholder="Enter fullname"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              required
            />
          </div>

          <div className="space-y-2">
            <label>Email</label>
            <Input
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              required
            />
          </div>

          <div className="space-y-2">
            <label>Username</label>
            <Input
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.currentTarget.value)}
              required
            />
          </div>

          <div className="space-y-2">
            <label>Password</label>
            <Input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              required
            />
          </div>

          <button
            disabled={isLoading}
            type="submit"
            className="disabled:bg-basf-light-blue/50 bg-basf-light-blue text-white text-lg px-3 py-2 rounded shadow mt-4 hover:scale-[1.01] active:scale-100 flex items-center justify-center"
          >
            {isLoading && <FaSpinner className="mr-2 h-4 w-4 animate-spin" />}
            Submit
          </button>
        </form>

        <div className="flex flex-col items-center mt-5">
          Already have an account?{" "}
          <Link to="/login" className="text-basf-light-blue font-bold">
            Login
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
});
