export const AuthWarning = () => {
  return (
    <div className="p-2 border border-sky-500 rounded bg-basf-light-blue/20 mb-5">
      This application is using Azure OpenAi servers, before we start please
      confirm. By using this playground, you acknowledge that you will not
      provide any internal or confidential data or personal data to the
      playground. You agree to use the playground only for general inquiries and
      not as source of confidential or personal data.
    </div>
  );
};
