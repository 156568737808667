import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../../app/stores/store";
import { Button, InputNumber, Space, Image, Col, Input } from "antd";
import { ChatContainerLayout } from "./components/ChatContainerLayout";
import { DotsLoader } from "./components/DotsLoader";

export default observer(function Dalle() {
  const { aiStore } = useStore();

  const { dalleImage } = aiStore;

  const [imageUrl, setImageUrl] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [promptHelperResponse, setPromptHelperResponse] = useState<string[]>(
    []
  );
  // const [isTermsAccepted,setIsTermsAccepted] = useState(true);
  const [value, setValue] = useState("");
  const [count, setCount] = useState(1);

  const onFinish = async (values: any) => {
    setIsLoading(true);
    setValue("");
    const results = dalleImage(values).finally(() => {
      setIsLoading(false);
    });
    const imgs = await results;

    setImageUrl((prevState) => {
      return [...prevState, ...imgs];
    });
    setValue("");
    setCount(1);
  };

  const handleChange = async (val: string) => {
    const response = await fetch(
      "https://gustavosta-magicprompt-stable-diffusion.hf.space/api/predict",
      // "https://gustavosta-magicprompt-stable-diffusion.hf.space/api/predict",
      {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          data: [val],
        }),
      }
    );
    const result = await response.json();
    const asdadq = result.data[0];
    const qwe = asdadq.split("\n");
    setPromptHelperResponse(qwe);
    return result;
  };

  const bottomDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => {
      bottomDivRef.current?.scrollIntoView({
        block: "end",
        inline: "nearest",
      });
    }, 50);
  }, [imageUrl]);

  return (
    <>
      <ChatContainerLayout
        warning="Generating stunning visuals takes time and effort. Please be patient as we work to produce a high-quality image for you"
        prompts={promptHelperResponse}
        promptDescription="Write some keyword below to generate one click prompts, which you can use to generate better AI outputs"
        onPromptClick={(val?: string) => {
          if (val) {
            setValue(val as string);
            setPromptHelperResponse((prevState) => {
              const arr = [...prevState];
              const i = arr.findIndex((item) => item === val);
              if (i > -1) {
                arr.splice(i, 1);
              }

              return arr;
            });
          }
        }}
        generatePrompt={handleChange}
      >
        <div
          className={`flex-1 overflow-auto p-5 pt-5 ${
            imageUrl.length === 0
              ? "flex flex-col justify-center items-center"
              : ""
          }`}
        >
          {imageUrl.length > 0 ? (
            imageUrl.map((asd) => (
              <Col xs={24} sm={12} className="mb-5">
                <div className="rounded overflow-hidden p-1 border bg-white">
                  <Image
                    className="rounded aspect-video"
                    style={{ objectFit: "cover" }}
                    placeholder={true}
                    src={asd}
                  />
                </div>

                <h6 className="mt-1 underline underline-offset-4 text-slate-700 text-sm">
                  DALL-E
                </h6>
              </Col>
            ))
          ) : (
            <div className="text-slate-400 text-center text-lg font-light max-w-2xl">
              <h1 className="text-2xl font-bold pb-2 lg:pb-5">DALL-E</h1>
              <span className="text-base lg:text-lg">
                To use DALL-E, you simply provide a text description of the
                image you want to create. For example, you could say 'A
                photorealistic painting of a cat riding a unicycle on a
                rainbow.' DALL-E will then generate a set of images that match
                your description.
              </span>
            </div>
          )}

          <div ref={bottomDivRef} />
        </div>

        {isLoading && (
          <DotsLoader text="Generating" dotsCount={3} className="p-4 pb-1" />
        )}

        <div className="flex flex-col md:flex-row-reverse md:items-end md:gap-x-5 p-4 gap-y-4 bg-white drop-shadow-xl border-t">
          <Space.Compact block size="large">
            <Input
              placeholder="Write your message here..."
              className="message-input"
              onChange={(e) => setValue(e.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onFinish({
                    prompt: value as string,
                    number: count,
                  });
                }
              }}
              value={value}
            />
            <Button
              className="message-button"
              disabled={isLoading}
              loading={isLoading}
              onClick={() =>
                onFinish({
                  prompt: value as string,
                  number: count,
                })
              }
            >
              Submit
            </Button>
          </Space.Compact>

          <div>
            <InputNumber
              onChange={(e) => setCount(e || 1)}
              value={count}
              size="large"
              min={1}
              max={10}
              defaultValue={1}
            />
          </div>
        </div>
      </ChatContainerLayout>
    </>
  );
});
