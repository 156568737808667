import { observer } from "mobx-react-lite";
import {
  FaPowerOff,
  FaBars,
  FaTimesCircle,
  FaQuestionCircle,
} from "react-icons/fa";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "antd";

import VastAILogo from "../../assets/vast-ai-logo.png";
import BASFLogo from "../../assets/logo-basf.png";
import { useStore } from "../stores/store";
import { NavItem } from "./NavItem";

export default observer(function NewNavBar() {
  const {
    userStore,
    userStore: { user, logout },
  } = useStore();

  const [navIsOpen, setNavIsOpen] = useState(false);

  const toggleNav = () => setNavIsOpen(!navIsOpen);

  const location = useLocation();

  useEffect(() => {
    setNavIsOpen(false);
  }, [location]);

  useEffect(() => {
    const getUser = async () => {
      await userStore.getLoggedIsUser();
    };

    getUser();
  }, [userStore.token]);

  return (
    <>
      <nav className="justify-between flex items-center bg-basf-blue drop-shadow">
        <div className="flex flex-none items-stretch">
          <div className="flex items-center ml-2 md:ml-4 py-5">
            <img src={VastAILogo} alt="" className="h-[2.3rem] md:h-10" />
          </div>
        </div>

        <ul className="hidden lg:flex flex-1 items-center justify-center gap-x-3">
          <NavItem to="/chatbot" name="Chat GPT" />
          <NavItem to="/dalle" name="Dall E" />
          <NavItem to="/mid-journey" name="Midjourney" />
          <NavItem to="/whisper" name="Whisper" />
        </ul>

        <div className="lg:hidden flex items-center">
          <Link className="block mr-5" to="/help">
            <Tooltip placement="bottom" title="Help">
              <FaQuestionCircle className="text-2xl hover:cursor-pointer text-white" />
            </Tooltip>
          </Link>
          <div className="p-4" onClick={toggleNav}>
            <FaBars className="text-3xl text-white" />
          </div>
        </div>

        <Link className="mr-5 hidden lg:block" to="/help">
          <Tooltip placement="bottom" title="Help">
            <FaQuestionCircle className="text-2xl hover:cursor-pointer text-white" />
          </Tooltip>
        </Link>

        <div className="hidden lg:flex flex-none items-stretch px-5 gap-x-5">
          <div className="flex-none flex flex-col max-w-lg justify-center text-white h-full">
            <div className="font-bold">Welcome,</div>
            <h6 className="line-clamp-1">{user?.displayName}</h6>
          </div>

          <div className="bg-white border-[.5px] border-white" />

          <div
            className="flex items-center gap-x-2 hover:cursor-pointer hover:scale-105"
            onClick={logout}
          >
            <FaPowerOff className="text-xl hover:cursor-pointer text-white" />
            <h6 className="text-white font-bold">Logout</h6>
          </div>
        </div>
      </nav>

      <div className="px-2 py-1 bg-gradient-to-r from-orange-400 via-orange-500 to-orange-400 drop-shadow">
        <h6 className="text-sm md:text-base text-center text-white">
          This application is using Azure OpenAI servers, kindly don't use any
          internal or confidential data or personal data to the playground.
        </h6>
      </div>

      <div
        className={`flex flex-col top-0 left-0 right-0 bottom-0 absolute z-40 duration-300 drop-shadow-xl bg-slate-800/50 ${
          !navIsOpen && "hidden"
        }`}
      >
        <div className="bg-basf-light-blue">
          <header className="justify-between flex items-center">
            <div className="flex flex-none">
              <div className="flex items-center ml-2 md:ml-6 py-5">
                <img src={VastAILogo} alt="" className="h-[2.3rem] md:h-10" />
              </div>
            </div>

            <div className="p-4" onClick={toggleNav}>
              <FaTimesCircle className="text-2xl text-white" />
            </div>
          </header>

          <div className="w-full px-3 mb-5">
            <div className="w-full border-b" />

            <div className="my-5 text-white text-xl bg-basf-blue/10 py-2 px-4 rounded-lg">
              <h6 className="font-bold">Welcome,</h6>
              <span className="font-light">{user?.displayName}</span>
            </div>

            <ul className="flex flex-col gap-y-3">
              <NavItem to="/chatbot" name="Chat GPT" />
              <NavItem to="/dalle" name="Dall E" />
              <NavItem to="/mid-journey" name="Midjourney" />
              <NavItem to="/whisper" name="Whisper" />
              <NavItem
                to="https://app.roqs.basf.net/generativeai"
                name="KDC Playground"
              />

              <div
                onClick={logout}
                className="flex items-center gap-x-2 text-white text-xl bg-basf-blue/10 py-2 px-4 rounded-lg"
              >
                <FaPowerOff className="text-xl hover:cursor-pointer text-white" />
                Logout
              </div>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
});
