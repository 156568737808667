import { observer } from "mobx-react-lite";
import { AuthLayout } from "./components/AuthLayout";
import { Link } from "react-router-dom";

export default observer(function LandingPage() {
  return (
    <AuthLayout>
      <div className="flex flex-col items-center gap-y-7">
        <h1 className="text-center text-white text-6xl leading-tight">
          VAST
          <br />
          AI Models
        </h1>

        <Link
          to="/login"
          className="flex text-white hover:bg-white hover:text-slate-900 hover:scale-105 active:scale-100 border-2 border-white px-8 py-3 text-2xl rounded font-bold duration-300 shadow-lg hover:shadow-2xl"
        >
          Login
        </Link>
      </div>
    </AuthLayout>
  );
});
