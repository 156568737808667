import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { User, UserFormValues } from "../models/user";
import { router } from "../router/Routes";
import { store } from "./store";
import { Auth, Profile } from "../api/midjourney-agent";
import { LoginDto, RegisterDto } from "../api/types";

export default class UserStore {
  user: User | null = null;

  token: string | null = localStorage.getItem("jwt-token") || null;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoggedIn() {
    return !!this.user || !!this.token;
  }
  get userName() {
    return !!this.user?.username;
  }

  temporaryLogin = async (creds: string) => {
    try {
      const myUser: User = {
        username: "dasdas",
        displayName: "asda",
        token: "qwasdas",
      };
      if (creds == "chemie23") {
        runInAction(() => (this.user = myUser));
        router.navigate("/chatbot");
        store.modalStore.closeModal();
        return true;
      }
      return false;
    } catch (error) {
      throw error;
    }
  };

  samlLogin = async (userName: string) => {
    try {
      const myUser: User = {
        username: userName,
        displayName: userName,
        token: "qwasdas",
      };
      if (userName != null) {
        runInAction(() => (this.user = myUser));
        router.navigate("/chatbot");
        store.modalStore.closeModal();
        return true;
      }
      return false;
    } catch (error) {
      throw error;
    }
  };

  login = async (creds: UserFormValues) => {
    try {
      const user = await agent.Account.login(creds);
      store.commonStore.setToken(user.token);
      runInAction(() => (this.user = user));
      router.navigate("/activities");
      store.modalStore.closeModal();
    } catch (error) {
      throw error;
    }
  };

  register = async (creds: UserFormValues) => {
    try {
      const user = await agent.Account.register(creds);
      store.commonStore.setToken(user.token);
      runInAction(() => (this.user = user));
      router.navigate("/activities");
      store.modalStore.closeModal();
    } catch (error) {
      throw error;
    }
  };

  logout = () => {
    store.commonStore.setToken(null);
    this.user = null;
    this.token = null;
    localStorage.clear();
    window.location.reload();
  };

  getUser = async () => {
    try {
      const user = await agent.Account.current();
      runInAction(() => (this.user = user));
    } catch (error) {
      console.log(error);
    }
  };

  setImage = (image: string) => {
    if (this.user) this.user.image = image;
  };

  setUserPhoto = (url: string) => {
    if (this.user) this.user.image = url;
  };

  setDisplayName = (name: string) => {
    if (this.user) this.user.displayName = name;
  };

  userLogin = async (loginDto: LoginDto) => {
    const response = await Auth.login(loginDto);
    localStorage.setItem("jwt-token", response.data.accessToken);
    runInAction(() => (this.token = response.data.accessToken));
  };

  userRegister = async (registerDto: RegisterDto) => {
    const response = await Auth.register(registerDto);
    localStorage.setItem("jwt-token", response.data.accessToken);
    runInAction(() => (this.token = response.data.accessToken));
  };

  getLoggedIsUser = async () => {
    const response = await Profile.get();

    const data = response.data;

    runInAction(
      () =>
        (this.user = {
          id: data.id,
          username: data.username,
          displayName: data.fullName,
          token: "randomtoken",
        }),
    );
  };
}
