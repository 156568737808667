import { FC } from "react";
import { Link, useLocation } from "react-router-dom";

export const NavItem: FC<{ to: string; name: string }> = ({ to, name }) => {
  const { pathname } = useLocation();

  return (
    <li>
      <Link
        to={to}
        className={`flex rounded-lg px-4 font-bold duration-200 ${
          pathname === to
            ? "bg-white !text-black"
            : "hover:bg-white hover:text-black"
        } hover:cursor-pointer py-2 px-3 text-white`}
      >
        {name}
      </Link>
    </li>
  );
};
