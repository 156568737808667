
import { Card, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import Link from "antd/lib/typography/Link";
import Cookies from 'js-cookie';
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../app/stores/store";


export default observer(function SamlResponse() {

    const { userStore } = useStore();


    var userEmail = {
        userDetail: Cookies.get('userNameVastAi')
    }

    // useEffect(() => {
    //     // if (hdwRegistry.size <= 1) loadHdwRequests();
    //     getUser();
    // }, [userEmail])

    // useEffect(() => {
    //     userStore.login({ email: userEmail.userDetail!, password: "Pa$$w0rd" });
    //     // console.log(userEmail.userDetail);
    // }, [userEmail])

    useEffect(() => {
        Cookies.remove('userNameVastAi');
        userStore.samlLogin( userEmail.userDetail!);
        // console.log(userEmail.userDetail);
    }, [userEmail])

    


    return (
        <>
            
            <Row gutter={[32, 32]}>
                {/* <Col span={8}>
                    <Link href="/hdwAiRequest">
                        <Card
                            hoverable
                            style={{ width: 'inherit' }}
                            cover={<img alt="example" src='/assets/categoryImages/ap.png' />}
                        >
                            <Meta title="Seed Solutions" description="www.basf.com" />
                        </Card>
                    </Link>
                </Col> */}
                <Col span={8}>
                    <Link href="/hdwAiRequest">
                        <Card
                            hoverable
                            style={{ width: 'inherit' }}
                            cover={<img alt="example" src='/assets/categoryImages/em.png' />}
                        >
                            <Meta title="Home Care and Industrial & Institutional Cleaning Solutions" description="www.basf.com" />
                        </Card>
                    </Link>
                </Col>
                {/* <Col span={8}>
                    <Link href="/hdwAiRequest">
                        <Card
                            hoverable
                            style={{ width: 'inherit' }}
                            cover={<img alt="example" src='/assets/categoryImages/personalcare.png' />}
                        >
                            <Meta title="Personal Care" description="www.basf.com" />

                        </Card>
                    </Link>
                </Col>
                <Col span={8}>
                    <Link href="/hdwAiRequest">
                        <Card
                            hoverable
                            style={{ width: 'inherit' }}
                            cover={<img alt="example" src='/assets/categoryImages/ed.png' />}
                        >
                            <Meta title="Architectural and Industrial Coatings" description="www.basf.com" />

                        </Card>
                    </Link>
                </Col>
                <Col span={8}>
                    <Link href="/hdwAiRequest">
                        <Card
                            hoverable
                            style={{ width: 'inherit' }}
                            cover={<img alt="example" src='/assets/categoryImages/ec.png' />}
                        >
                            <Meta title="Refinish Competence Centre" description="www.basf.com" />

                        </Card>
                    </Link>
                </Col>
                <Col span={8}>
                    <Link href="/hdwAiRequest">
                        <Card
                            hoverable
                            style={{ width: 'inherit' }}
                            cover={<img alt="example" src='/assets/categoryImages/en.png' />}
                        >
                            <Meta title="Food Solutions" description="www.basf.com" />

                        </Card>
                    </Link>
                </Col>
                <Col span={8}>
                    <Link href="/hdwAiRequest">
                        <Card
                            hoverable
                            style={{ width: 'inherit' }}
                            cover={<img alt="example" src='/assets/categoryImages/pm.png' />}
                        >
                            <Meta title="Performance Materials" description="www.basf.com" />

                        </Card>
                    </Link>
                </Col> */}

            </Row>


            {/* <Grid>
                <Grid.Column width='10'>
                    <HdwList />
                </Grid.Column>
                <Grid.Column width='6'>
                    <HdwFilters />
                </Grid.Column>
            </Grid> */}
        </>
    )
})