import { FC, PropsWithChildren } from "react";
import { ChatPromptHelper } from "./ChatPromptHelper";

type Props = PropsWithChildren & {
  prompts: any[];
  promptDescription: string;
  onPromptClick: (val?: string) => void;
  generatePrompt: (val: string) => void;
  warning?: any;
};

export const ChatContainerLayout: FC<Props> = ({
  children,
  onPromptClick,
  promptDescription,
  prompts,
  generatePrompt,
  warning,
}) => {
  return (
    <div className="flex flex-1 h-full overflow-hidden lg:py-5 lg:pl-5 justify-between relative">
      <div className="flex h-full flex-1 flex-row-reverse overflow-auto hover:drop-shadow border border-slate-300 bg-slate-50 lg:max-w-[75rem] lg:w-[75rem] lg:rounded-lg lg:m-auto">
        <div className="flex flex-col flex-1">
          <>
            {warning && (
              <div className="px-2 py-1 bg-gradient-to-r from-orange-400 via-orange-500 to-orange-400 drop-shadow">
                <h6 className="text-sm md:text-base text-center text-white">
                  {warning}
                </h6>
              </div>
            )}
            {/* Children */}
            {children}
            {/* Children ends */}
          </>
        </div>
      </div>

      {/* Prompt helper */}
      <ChatPromptHelper
        onPromptClick={onPromptClick}
        promptDescription={promptDescription}
        prompts={prompts}
        generatePrompt={generatePrompt}
      />
    </div>
  );
};
