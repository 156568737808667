import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import ActivityDashboard from "../../features/activities/dashboard/ActivityDashboard";
import ActivityDetails from "../../features/activities/details/ActivityDetails";
import ActivityForm from "../../features/activities/form/ActivityForm";
import NotFound from "../../features/errors/NotFound";
import AiPage from "../../features/home/AiPage";
import ServerError from "../../features/errors/ServerError";
import TestErrors from "../../features/errors/TestError";
import ProfilePage from "../../features/profiles/ProfilePage";
import App from "../layout/App";
import RequireAuth from "./RequireAuth";
import Whisper from "../../features/ai/Whisper";
import Dalle from "../../features/ai/Dalle";
import PromptGenerator from "../../features/ai/PromptGenerator";
import FineTune from "../../features/ai/FineTune";
import SamlResponse from "../../features/home/SamlResponse";
import { MidJourney } from "../../features/ai/MidJourney";
import Composer from "../../features/ai/Composer";
import LoginPage from "../../features/auth/LoginPage";
import LandingPage from "../../features/auth/LandingPage";
import RegisterPage from "../../features/auth/RegisterPage";
import { HelpPage } from "../../features/home/HelpPage";

export const routes: RouteObject[] = [
  { path: "/", element: <LandingPage /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/register", element: <RegisterPage /> },
  {
    path: "/",
    element: <App />,
    children: [
      {
        element: <RequireAuth />,
        children: [
          { path: "help", element: <HelpPage /> },
          { path: "activities", element: <ActivityDashboard /> },
          { path: "activities/:id", element: <ActivityDetails /> },
          { path: "createActivity", element: <ActivityForm key="create" /> },
          { path: "manage/:id", element: <ActivityForm key="manage" /> },
          { path: "profiles/:username", element: <ProfilePage /> },
          { path: "errors", element: <TestErrors /> },
          { path: "chatbot", element: <AiPage /> },
          { path: "whisper", element: <Whisper /> },
          { path: "dalle", element: <Dalle /> },
          // { path: 'composer', element: <Composer /> },
          { path: "mid-journey", element: <MidJourney /> },
          { path: "finetune", element: <FineTune /> },
          { path: "promptgenerator", element: <PromptGenerator /> },
        ],
      },
      { path: "not-found", element: <NotFound /> },
      { path: "samlresponse", element: <SamlResponse /> },
      { path: "server-error", element: <ServerError /> },
      { path: "*", element: <Navigate replace to="/not-found" /> },
    ],
  },
];

export const router = createBrowserRouter(routes);
