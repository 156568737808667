import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Alert, Button, Input, Card, message, Col, Row } from "antd";
import { router } from "../../app/router/Routes";

export default observer(function LoginForm() {
  const { userStore } = useStore();
  const [password, setPassword] = useState(
    process.env.NODE_ENV === "development" ? "chemie23" : "",
  );
  const [messageApi, contextHolder] = message.useMessage();

  const handleChange2 = async () => {
    userStore.temporaryLogin(password).then((result) => {
      if (result == true) {
        success();
        router.navigate("/chatbot");
      } else {
        error();
      }
    });
    //setIsUserLoggedIn(await qwe);
    // if(await qwe==true){
    //     success();
    // }else{
    //     error();
    // }
  };

  const success = () => {
    messageApi.open({
      type: "success",
      content: "This is a success message",
    });
  };
  const error = () => {
    messageApi.open({
      type: "error",
      content: "Wrong password!",
    });
  };

  return (
    <Row justify={"center"}>
      <Col xs={24} sm={16} md={14} lg={12}>
        <Card>
          <Alert
            message="This application is using Azure OpenAi servers, before we start please confirm. By using this playground, you acknowledge that you will not provide any internal or confidential data or personal data to the playground. You agree to use the playground only for general inquiries and not as source of confidential or personal data."
            type="info"
          />
          {/* <Alert message="This application is using OpenAi servers, before we start please confirm. By using this playground, you acknowledge that you will not provide any internal or confidential data or personal data to the playground. You agree to use the playground only for general inquiries and not as source of confidential or personal data." type="info" closeText="Accept" onClose={() => setIsTermsAccepted(false)} /> */}
          <br></br>

          {process.env.NODE_ENV === "development" ||
          window.location.hostname === "vastaiwebapp.azurewebsites.net" ||
          window.location.hostname === "vastaidev.intranet.basf.com" ? (
            <>
              <p>Password</p>
              <Input
                onChange={(a) => setPassword(a.target.value)}
                value={password}
              />
              <br />
              <br />
              <Button
                className="bg-basf-light-blue"
                type="primary"
                onClick={handleChange2}
              >
                Login
              </Button>
            </>
          ) : (
            <Button href="https://myapps.microsoft.com/signin/c76ce15c-af91-4c15-85a4-667f00984fc9?tenantId=ecaa386b-c8df-4ce0-ad01-740cbdb5ba55">
              Accept
            </Button>
          )}

          {/* <Button disabled={isTermsAccepted} type="primary" onClick={handleChange2} >Login</Button> */}
          <br></br>
          {contextHolder}
        </Card>
      </Col>
    </Row>
    // <Formik
    //     initialValues={{ email: '', password: '', error: null }}
    //     onSubmit={(values, { setErrors }) =>
    //         userStore.login(values).catch(error => setErrors({ error: 'Invalid email or password' }))}
    // >
    //     {({ handleSubmit, isSubmitting, errors }) => (
    //         <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
    //             <Header as='h2' content='Login to Reactivities' color="teal" textAlign="center" />
    //             <MyTextInput placeholder="Email" name='email' />
    //             <MyTextInput placeholder="Password" name='password' type='password' />
    //             <ErrorMessage name='error' render={() =>
    //                 <Label style={{ marginBottom: 10 }} basic color='red' content={errors.error} />} />
    //             <Button loading={isSubmitting} positive content='Login' type="submit" fluid />
    //         </Form>
    //     )}

    // </Formik>
  );
});
