import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Alert, Card, Divider, Select, Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { useStore } from "../../app/stores/store";

export default observer(function Whisper() {
  const { Dragger } = Upload;
  const { aiStore } = useStore();
  const { whisperTranscribeTest } = aiStore;
  const [transcription, setTranscription] = useState("");
  const [transcribeOrTranslate, setTranscribeOrTranslate] =
    useState("transcribe");
  const [showupload, setShowupload] = useState(true);

  const handleChange = (value: string) => {
    setTranscribeOrTranslate(value);
  };

  // const props: UploadProps = {
  //     name: 'file',
  //     multiple: true,
  //     action: 'http://localhost:5000/api/openai/whisper',
  //     onChange(info) {
  //         const { status } = info.file;
  //         if (status !== 'uploading') {
  //             console.log(info.file, info.fileList);
  //         }
  //         if (status === 'done') {
  //             message.success(`${info.file.name} file uploaded successfully.`);
  //             setTranscription(info.file.response);
  //         } else if (status === 'error') {
  //             message.error(`${info.file.name} file upload failed.`);
  //         }
  //     },
  //     onDrop(e) {
  //         console.log('Dropped files', e.dataTransfer.files);
  //     },
  // };

  const handleUpload = async (options: any) => {
    const { file, onSuccess, onError } = options;

    setShowupload(true);

    whisperTranscribeTest(file, transcribeOrTranslate)
      .then((res) => {
        message.success("upload successfully.");
        setTranscription(res.data);
      })
      .catch(() => {
        message.error("upload failed.");
      })
      .finally(() => {
        setShowupload(false);
      });
  };

  return (
    <div className="flex flex-1 h-full overflow-hidden lg:py-5 lg:pl-5">
      <div className="flex h-full flex-1 flex-row-reverse overflow-auto hover:drop-shadow border border-slate-300 bg-slate-50 lg:max-w-[75rem] lg:w-[75rem] lg:rounded-lg lg:m-auto">
        <div className="flex flex-col flex-1 p-5 gap-5">
          <div className="flex flex-col gap-4">
            <Alert
              message="Whisper is a pre-trained model for automatic speech recognition (ASR) and speech translation. It can transcribe speech audio into text in the language it is spoken (ASR) as well as translated into English (speech translation)."
              type="warning"
            />

            <Alert
              message="Drag and drop audio or video file to generate text file with speech recognition for transcription or translation"
              type="info"
            />

            <Dragger
              showUploadList={showupload}
              customRequest={handleUpload}
              accept=".mp3,.mp4,.mpeg,.mpga,.m4a,.wav,.webm"
            >
              {/* <Dragger {...props} > */}
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Strictly prohibited from uploading company data or other banned
                files.
              </p>
              <p className="ant-upload-hint">
                formats: mp3, mp4, mpeg, mpga, m4a, wav, or webm
              </p>
              <p className="ant-upload-hint">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </div>

          {/* Result */}
          <Card
            bordered={false}
            style={{
              width: "100%",
              border: "1px solid #dedede",
            }}
            className="mt-10"
          >
            <Select
              defaultValue="Transcribe"
              style={{ width: 120 }}
              onChange={handleChange}
              options={[{ value: "transcribe", label: "Transcribe" }]}
            />
            <Divider plain></Divider>
            <p>{transcription}</p>
          </Card>
        </div>
      </div>
    </div>
  );
});
