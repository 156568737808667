import { Button, Divider, Input, Modal, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React, { FC, useEffect, useRef, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import { Midjourney, midJourneySocket } from "../../app/api/midjourney-agent";
import { useStore } from "../../app/stores/store";
import { ChatContainerLayout } from "./components/ChatContainerLayout";

const LABEL = {
  U1: "Upscale 1",
  U2: "Upscale 2",
  U3: "Upscale 3",
  U4: "Upscale 4",
};

type FormValues = {
  prompt: string;
};

type MidJourneyItem = {
  id: string;
  userId: string;
  prompt: string;
  progress: null | string;
  uri: null | string;
  createdAt: string;
  result: any;
  options: string | null;
  isFailed: boolean;
  error: string;
};

type Props = {
  id: string;
  prompt: string;
  progress: null | string;
  imgUrl: null | string;
  createdAt: string;
  onImageClick: (img: string) => void;
  hideDivider: boolean;
  options: any;
  selectedOptions: number[];
  onUpscaleClick: (index: number, id: string) => void;
  isFailed: boolean;
  error: string;
};

const PromptItem: FC<Props> = ({
  id,
  prompt,
  progress,
  imgUrl,
  createdAt,
  onImageClick,
  hideDivider,
  options,
  selectedOptions,
  onUpscaleClick,
  isFailed,
  error,
}) => {
  const [loading, setLoading] = useState(0);

  return (
    <div>
      <div>
        <small>
          {`${moment(createdAt).fromNow()} at ${moment(createdAt).format(
            "hh:mm A",
          )}`}
        </small>
      </div>
      <div className="mb-1">
        <b>{prompt}</b> -(
        {progress
          ? progress
          : isFailed
          ? "Failed with error"
          : "Waiting to start"}
        )
      </div>

      {progress && imgUrl ? (
        <div>
          <img
            className="midjourney-thumbnail rounded"
            src={
              progress === "done" ? `${imgUrl}?width=300&height=300` : imgUrl
            }
            alt={prompt}
            onClick={() => onImageClick(imgUrl)}
          />
        </div>
      ) : isFailed ? (
        <div>{error}</div>
      ) : (
        <LoadingOutlined />
      )}

      <div className="grid grid-cols-2 w-[300px] mt-5 gap-2">
        {options
          .filter((opt: any) => {
            return ["U1", "U2", "U3", "U4"].includes(opt.label);
          })
          .map((item: any, index: number) => {
            const isDisabled = !!selectedOptions.find((op) => op === index + 1);

            return (
              <button
                key={item.label}
                onClick={async () => {
                  setLoading(index + 1);
                  await onUpscaleClick(index + 1, id);
                  setLoading(0);
                }}
                disabled={isDisabled}
                className={`${
                  isDisabled || index + 1 === loading
                    ? "text-basf-light-blue bg-white"
                    : "shadow bg-basf-light-blue text-white active:scale-95 hover:text-basf-light-blue hover:bg-white"
                } duration-200 border py-1 px-2 rounded`}
              >
                {index + 1 === loading
                  ? "Please wait..."
                  : LABEL[item.label as keyof typeof LABEL]}
              </button>
            );
          })}
      </div>

      {!hideDivider && <Divider />}
    </div>
  );
};

export const MidJourney = () => {
  const lastElm = useRef<HTMLDivElement>(null);

  const {
    userStore: { user },
  } = useStore();

  const [isLoading, setIsLoading] = useState(true);
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [results, setResults] = useState<MidJourneyItem[]>([]);

  const [input, setInput] = useState<string>("");
  const [promptHelperResponse, setPromptHelperResponse] = useState<string[]>(
    [],
  );

  const scrollToBottom = () => {
    setTimeout(() => {
      lastElm.current?.scrollIntoView({
        block: "end",
        inline: "nearest",
      });
    }, 50);
  };

  const onSubmitPrompt = async ({ prompt }: FormValues) => {
    try {
      setFormIsLoading(true);
      const result = await Midjourney.createPrompt(prompt);
      setResults((prevState) => {
        const s = [...prevState];
        s.push(result.data);
        return s;
      });
      setInput("");
    } catch (e: any) {
      toast.error(e?.message);
    } finally {
      setFormIsLoading(false);
      scrollToBottom();
    }
  };

  const upscaleHandler = async (index: number, messageId: string) => {
    try {
      setFormIsLoading(true);
      const result = await Midjourney.upscale(messageId, index);
      setResults((prevState) => {
        const s = [...prevState];
        s.push(result.data);

        const i = s.findIndex((item) => item.id === messageId);
        if (i > -1) {
          if (s[i].options) {
            const str = s[i].options as string;
            const arr = JSON.parse(str);
            arr.push(index);
            s[i].options = JSON.stringify(arr);
          } else {
            const arr = [index];
            s[i].options = JSON.stringify(arr);
          }
        }

        return s;
      });
    } catch (e: any) {
      toast.error(e?.message);
    } finally {
      setFormIsLoading(false);
      scrollToBottom();
    }
  };

  const getResults = async () => {
    try {
      setIsLoading(true);
      const response = await Midjourney.results();
      setResults([...response.data.results]);
    } catch (e: any) {
      toast.error(e?.message);
    } finally {
      setIsLoading(false);
      scrollToBottom();
    }
  };

  useEffect(() => {
    getResults().then();

    midJourneySocket.connect();

    midJourneySocket.on("connect", () => {
      console.log("connect.v1", midJourneySocket.id);
    });

    midJourneySocket.on("disconnect", () => {
      console.log("disconnect.v1", midJourneySocket.id);
    });

    midJourneySocket.on("imagine:updates", (data: MidJourneyItem) => {
      setResults((prevState) => {
        const midJourneyItems = [...prevState];

        const index = midJourneyItems.findIndex((item) => item.id === data.id);

        if (index > -1) {
          midJourneyItems[index] = { ...data };
          return midJourneyItems;
        }

        return prevState;
      });
    });

    return () => {
      midJourneySocket.disconnect();
      midJourneySocket.removeAllListeners();
    };
  }, []);

  const handlePromptHelper = async (val: string) => {
    if (!val) {
      return null;
    }

    const response = await fetch(
      "https://gustavosta-magicprompt-stable-diffusion.hf.space/api/predict",
      {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          data: [val],
        }),
      },
    );
    const result = await response.json();
    const asdadq = result.data[0];
    const qwe = asdadq.split("\n");
    setPromptHelperResponse(qwe);
    setInput("");
    return result;
  };

  return (
    <ChatContainerLayout
      warning="Generating stunning visuals takes time and effort. Please be patient as we work to produce a high-quality image for you"
      prompts={promptHelperResponse}
      promptDescription="Write some keyword below to generate one click prompts, which you can use to generate better AI outputs"
      onPromptClick={(val?: string) => {
        if (val) {
          setInput(val as string);
          setPromptHelperResponse((prevState) => {
            const arr = [...prevState];
            const i = arr.findIndex((item) => item === val);
            if (i > -1) {
              arr.splice(i, 1);
            }

            return arr;
          });
        }
      }}
      generatePrompt={handlePromptHelper}
    >
      <div
        className={`flex-1 overflow-auto p-5 pt-5 ${
          results.length === 0
            ? "flex flex-col justify-center items-center"
            : ""
        }`}
      >
        {results.length > 0 ? (
          results.map((item: MidJourneyItem, index: number) => {
            return (
              <PromptItem
                id={item.id}
                key={item.id}
                prompt={item.prompt}
                progress={item.progress}
                imgUrl={item.uri}
                createdAt={item.createdAt}
                onImageClick={(img: string) => {
                  setModalIsOpen(true);
                  setModalImage(img);
                }}
                hideDivider={results.length - 1 === index}
                options={item?.result?.options || []}
                selectedOptions={item.options ? JSON.parse(item.options) : []}
                onUpscaleClick={upscaleHandler}
                isFailed={item.isFailed}
                error={item.error}
              />
            );
          })
        ) : isLoading ? (
          <div className="flex-1 flex items-center justify-center">
            <LoadingOutlined />
          </div>
        ) : (
          <div className="text-slate-400 text-center text-lg font-light max-w-2xl">
            <h1 className="text-2xl font-bold pb-2 lg:pb-5">Midjourney</h1>
            <span className="text-base lg:text-lg">
              To use Midjourney, you simply provide a text description of the
              image you want to create. For example, you could say 'A
              photorealistic painting of a cat riding a unicycle on a rainbow.'
              Midjourney will then generate a set of images that match your
              description.
            </span>
          </div>
        )}

        <div ref={lastElm} />

        <Modal
          className="midjourney-modal__container"
          width="auto"
          title={null}
          centered
          open={modalIsOpen}
          onCancel={() => {
            setModalIsOpen(false);
            setModalImage("");
          }}
          destroyOnClose={true}
          footer={[
            <a
              key="open-in-browser"
              className="open-in-browser"
              href={modalImage}
              target="_blank"
            >
              Open in Browser
            </a>,
          ]}
          closeIcon={false}
          maskStyle={{ backgroundColor: "rgba(0, 0, 0, .9)" }}
        >
          {modalImage && (
            <img
              src={`${modalImage}?width=571&height=571`}
              alt="Image Zoom"
              className="zoom-image"
            />
          )}
        </Modal>
      </div>

      <div className="flex flex-col md:flex-row-reverse md:items-end md:gap-x-5 p-4 gap-y-4 bg-white drop-shadow-xl border-t">
        <Space.Compact block size="large">
          <Input
            placeholder="Write your message here..."
            className="message-input"
            onChange={(e) => setInput(e.currentTarget.value)}
            value={input}
          />
          <Button
            className="message-button"
            disabled={formIsLoading}
            loading={formIsLoading}
            onClick={() =>
              onSubmitPrompt({
                prompt: input,
              })
            }
          >
            Submit
          </Button>
        </Space.Compact>
      </div>
    </ChatContainerLayout>
  );
};
