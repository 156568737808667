import axios, { AxiosHeaders, AxiosPromise } from "axios";
import { toast } from "react-toastify";
import { io } from "socket.io-client";

import { AuthResponse, LoginDto, ProfileResponse, RegisterDto } from "./types";
import { store } from "../stores/store";

const midJourneySocket = io(
  `${process.env.REACT_APP_MIDJOURNEY_URL}/mid-journey`,
  {
    autoConnect: false,
    upgrade: false,
    transports: ["websocket"],
  }
);

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const Endpoints = {
  auth: {
    login: "/auth/log-in",
    register: "/auth/register",
  },
  profile: {
    get: "/profile",
  },

  sendImaginePrompt: "/mid-journey/imagine",
  sendImagineUpscale: "/mid-journey/upscale",
  getResults: "/mid-journey/imagine/results",
  azure: {
    generatePrompt: "chatgpt/prompt",
  },
};

Axios.interceptors.request.use(
  function (config) {
    if (!config?.headers) {
      config.headers = new AxiosHeaders();
    }

    const token = localStorage.getItem("jwt-token");

    if (token) {
      config.headers.set("Authorization", `Bearer ${token}`);
    }

    config.headers.set("Accept", "application/json");

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response.status === 401 &&
      ![Endpoints.auth.login, Endpoints.auth.register].includes(
        error.response.config.url
      )
    ) {
      toast.error("unauthorised");
      store.userStore.logout();
      return null;
    }

    if (error?.response?.data?.message) {
      return Promise.reject(new Error(error?.response?.data?.message));
    }

    return Promise.reject(error);
  }
);

const Auth = {
  login: async (loginDto: LoginDto): Promise<AxiosPromise<AuthResponse>> =>
    Axios.post(Endpoints.auth.login, loginDto),

  register: async (
    registerDto: RegisterDto
  ): Promise<AxiosPromise<AuthResponse>> =>
    Axios.post(Endpoints.auth.register, registerDto),
};

const Profile = {
  get: async (): Promise<AxiosPromise<ProfileResponse>> =>
    Axios.get(Endpoints.profile.get),
};

const Midjourney = {
  results: async () => Axios.get(Endpoints.getResults),
  createPrompt: async (prompt: string) =>
    Axios.post(Endpoints.sendImaginePrompt, {
      prompt,
    }),
  upscale: async (msgId: string, index: number) =>
    Axios.post(Endpoints.sendImagineUpscale, {
      index,
      msgId,
    }),
};

const generateChatGPTPrompt = async (input: string): Promise<string> => {
  const response = await Axios.post(
    `${process.env.REACT_APP_API_URL}/${Endpoints.azure.generatePrompt}`,
    {
      userInput: input,
    }
  );

  return response.data;
};

export { midJourneySocket, generateChatGPTPrompt, Midjourney, Auth, Profile };
