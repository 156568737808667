import { FC } from "react";

type Props = {
  title: string;
  subtitle?: string;
};

export const PromptHeader: FC<Props> = ({ subtitle, title }) => {
  return (
    <div className="border-b px-4 py-3 bg-basf-light-blue">
      <h3 className="text-lg font-bold text-center text-white">
        {title}{" "}
        {subtitle ? (
          <small className="text-base font-normal text-white">
            (Click on generated prompt to use)
          </small>
        ) : null}
      </h3>
    </div>
  );
};
