import { FC, PropsWithChildren, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../../app/stores/store";

export const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const { userStore } = useStore();

  useEffect(() => {
    if (userStore.token) {
      navigate("/chatbot");
    }
  }, [userStore.token]);

  return (
    <div className="gradient h-screen flex flex-col items-center justify-center">
      <ToastContainer position="bottom-right" hideProgressBar theme="colored" />

      {children}
    </div>
  );
};
