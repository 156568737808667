import { observer } from "mobx-react-lite";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Select, Input, Button, Space, Typography } from "antd";

import { useStore } from "../../app/stores/store";
import { ChatTest, ChatTestFormValues } from "../../app/models/chatTest";
import { Prompt } from "../../app/models/prompt";
import { CompletionParametersModel } from "../../app/models/completionParametersModel";
import { ChatContainerLayout } from "../ai/components/ChatContainerLayout";
import { generateChatGPTPrompt } from "../../app/api/midjourney-agent";
import { LoadingOutlined } from "@ant-design/icons";
import { DotsLoader } from "../ai/components/DotsLoader";

const { Paragraph } = Typography;

export default observer(function AiPage() {
  const {
    aiStore,
    userStore: { user },
  } = useStore();
  const { chatGpt, fineTunedModelsList, completionParameters } = aiStore;

  const [array, setArray] = useState<ChatTest[]>([]);
  const [arrayInput, setArrayInput] = useState<ChatTest[]>([]);
  const firstUpdate = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const [promptHelperResponse, setPromptHelperResponse] = useState<string[]>(
    []
  );
  const [promptHelperIsLoading, setPromptHelperIsLoading] = useState(false);
  const [input, setInput] = useState("");
  const [selectedModel, setSelectedModel] = useState<Prompt>({
    text: "gpt-3.5-turbo",
  });
  const [fineTunedModelsList1, setFineTunedModelsList1] = useState<string[]>(
    []
  );
  // const [chatParameters, setChatParameters] = useState<ChatParameters>({ model: "gpt-3.5-turbo", N: 1, Temperature: 1 ,ChatHistory:[]});
  const [inputCompletionParameters, setInputCompletionParameters] =
    useState<CompletionParametersModel>({
      model: "gpt-3.5-turbo",
      n: 1,
      prompt: "",
      temperature: 1,
    });

  const [modelTempreture, setModelTempreture] = useState(1);
  const [numberOfResult, setNumberOfResult] = useState(1);
  const [chatInput, setChatInput] = useState("");
  const [compareInput, setCompareInput] = useState("");

  const [prompts, setPrompts] = useState<string>("");
  const [promptsShow, setPromptsShow] = useState<boolean>(false);
  const [reloadPrompt, setReloadPrompt] = useState<boolean>(false);

  useEffect(() => {
    // fineTunedModelsList().then((a) => {
    //   setFineTunedModelsList1(a);
    // });
    setFineTunedModelsList1(["gpt-3.5-turbo"]);
  }, []);

  const handleModelChange = (value: string) => {
    setSelectedModel({ text: value });
    inputCompletionParameters.model = value;
  };

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    onFinish();
  }, [arrayInput]);

  const onFinish = async () => {
    if (selectedModel?.text.includes(":")) {
      const xcv = completionParameters(inputCompletionParameters);
      const qwer = new ChatTestFormValues();
      qwer.prompt = await xcv;
      qwer.user = "ai";
      setIsLoading(true);
      setArray(() => [...array, qwer]);
    } else {
      const asd = chatGpt(array);
      const qwer = new ChatTestFormValues();
      qwer.prompt = await asd;
      qwer.user = "ai";
      setIsLoading(true);
      setArray(() => [...array, qwer]);

      if (array.length > 0) {
        if (compareInput !== prompts) {
          setPromptsShow(true);
        } else {
          setPrompts("");
        }
      }
    }
  };

  const handleChange = async (val: string) => {
    if (!val) {
      return null;
    }

    setPromptHelperIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/${"chatgpt/prompt"}`,
      {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          userInput: val,
        }),
      }
    );
    const result = await response.json();

    const asdadq = result.response3;
    const qwe = asdadq.split("\n");

    setInput("");
    setPromptHelperResponse(qwe);
    setPromptHelperIsLoading(false);
    return result;
  };

  // const handleChange = async () => {
  //   if (!input) {
  //     return null;
  //   }

  //   setPromptHelperIsLoading(true);
  //   const response = await fetch(
  //     "https://merve-chatgpt-prompt-generator.hf.space/run/predict",
  //     {
  //       headers: { "Content-Type": "application/json" },
  //       method: "POST",
  //       body: JSON.stringify({
  //         data: [input],
  //       }),
  //     },
  //   );
  //   setInput("");
  //   const result = await response.json();
  //   const asdadq = result.data[0];
  //   const qwe = asdadq.split("\n");
  //   setPromptHelperResponse(qwe);
  //   setPromptHelperIsLoading(false);
  //   return result;
  // };

  const bottomDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => {
      bottomDivRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 0);
  }, [array]);

  const onSubmit = (value: string = "") => {
    setPromptsShow(false);

    if (!value && !chatInput) {
      return null;
    }
    setArrayInput([...array, { prompt: value || chatInput, user: "user" }]);
    setArray([
      ...array,
      { prompt: value || chatInput, user: "user" },
      { prompt: numberOfResult.toString(), user: "n" },
      { prompt: modelTempreture.toString(), user: "tempreture" },
      { prompt: selectedModel.text, user: "model" },
    ]);

    inputCompletionParameters.prompt = value || chatInput;
    inputCompletionParameters.n = numberOfResult;
    inputCompletionParameters.temperature = modelTempreture;

    setIsLoading(false);

    setChatInput("");

    // Call API for prompt
    generateChatGPTPrompt(chatInput).then((result) => {
      setPrompts(result);
    });
  };

  return (
    <ChatContainerLayout
      prompts={promptHelperResponse}
      promptDescription="Write some keyword below to generate one click prompts, which you can use to generate better AI outputs"
      onPromptClick={(val?: string) => {
        if (val) {
          setPromptsShow(false);
          setPrompts(val);
          setChatInput(val);
          setCompareInput(val);
          setPromptHelperResponse((prevState) => {
            const arr = [...prevState];
            const i = arr.findIndex((item) => item === val);
            if (i > -1) {
              arr.splice(i, 1);
            }
            return arr;
          });
        }
      }}
      generatePrompt={handleChange}
    >
      <div
        className={`flex-1 overflow-auto p-5 pt-5 ${
          array.length === 0 ? "flex flex-col justify-center items-center" : ""
        }`}
      >
        {array.length > 0 ? (
          array.map((item, index) => {
            if (item.user == "ai") {
              return (
                <div
                  className="flex flex-col items-start justify-start mb-5"
                  key={index}
                >
                  <div className="bg-white p-5 border border-slate-300 max-w-4xl rounded-xl rounded-bl whitespace-pre-wrap">
                    <p>{item.prompt}</p>
                  </div>

                  <div className="flex gap-x-2">
                    <h6 className="mt-1 underline underline-offset-4 text-slate-700 text-sm">
                      VAST AI
                    </h6>
                    <Paragraph
                      copyable={{ text: item.prompt }}
                      style={{ marginBottom: 0 }}
                    />
                  </div>
                </div>
              );
            }

            if (item.user == "user") {
              return (
                <div
                  className="flex flex-col items-end justify-end mb-5"
                  key={index}
                >
                  <div className="border bochatbotrder-basf-light-blue bg-[rgb(230,248,255)] p-5 max-w-4xl rounded-xl rounded-br whitespace-pre-wrap">
                    {item.prompt}
                  </div>

                  <h6 className="mt-1 underline underline-offset-4 text-slate-700 text-sm">
                    {user?.displayName} (You)
                  </h6>
                </div>
              );
            }
          })
        ) : (
          <div className="text-slate-400 text-center text-lg font-light max-w-2xl">
            <h1 className="text-2xl font-bold pb-2 lg:pb-5">ChatGPT</h1>
            <span className="text-base lg:text-lg">
              ChatGPT is a large language model (LLM) chatbot developed by Open
              AI. It is trained on a massive dataset of text and code, and can
              be used to generate text, translate languages, write different
              kinds of creative content, and answer your questions in an
              informative way. You can select creativity level and model
              including fine-tuned by individuals
            </span>
          </div>
        )}
        <div ref={bottomDivRef} />
      </div>

      <div>
        <div className="grid grid-cols-1 p-4 gap-2">
          {!isLoading && (
            <DotsLoader text="Thinking" dotsCount={3} className="-mb-3" />
          )}

          {promptsShow && prompts && (
            <>
              <div
                className={`${
                  reloadPrompt ? "invisible translate-x-full" : "flex"
                } duration-300 flex border border-sky-600 hover:bg-sky-100 rounded p-2 cursor-pointer`}
                onClick={() => {
                  setChatInput(prompts);
                  setCompareInput(prompts);
                  setPromptsShow(false);
                }}
              >
                <p>
                  <span className="text-sky-700">
                    Click to try this suggestion{" "}
                  </span>
                  <span className="font-bold">{prompts}</span>
                </p>
              </div>
              <div>
                <a
                  className="text-sky-700 cursor-pointer hover:underline"
                  onClick={() => {
                    setReloadPrompt(true);
                    generateChatGPTPrompt(compareInput).then((result) => {
                      setPrompts(result);
                      setReloadPrompt(false);
                    });
                  }}
                >
                  Generate more prompts {reloadPrompt && <LoadingOutlined />}
                </a>
              </div>
            </>
          )}
        </div>

        <div className="flex flex-col md:flex-row-reverse md:items-end md:gap-x-5 p-4 gap-y-4 bg-white drop-shadow-xl border-t">
          <Space.Compact block size="large">
            <Input
              placeholder="Write your message here..."
              className="message-input"
              onChange={(e) => {
                setChatInput(e.currentTarget?.value);
                setCompareInput(e.currentTarget?.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSubmit();
                }
              }}
              value={chatInput}
            />
            <Button
              className="message-button"
              disabled={!isLoading}
              loading={!isLoading}
              onClick={() => {
                onSubmit();
              }}
            >
              Submit
            </Button>
          </Space.Compact>

          <div>
            <Select
              className="w-full md:w-44 model-select"
              showSearch
              optionFilterProp="children"
              onChange={handleModelChange}
              options={fineTunedModelsList1.map((item) => ({
                value: item,
                label: item,
              }))}
              value={selectedModel.text}
              size="large"
            />
          </div>
        </div>
      </div>
    </ChatContainerLayout>
  );
});
