import { FC, useState, useEffect } from "react";
import { cn } from "../../../lib/utils";

type Props = {
  text: string;
  dotsCount: number;
  className?: string;
};

export const DotsLoader: FC<Props> = ({ text, dotsCount, className }) => {
  const [visibleIndex, setVisibleIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleIndex((prev) => (prev + 1) % (dotsCount + 1));
    }, 500);

    return () => clearInterval(interval);
  }, [dotsCount]);

  const dots = Array.from({ length: visibleIndex }, () => ".").join("");

  return (
    <div className={cn("w-fit animate-pulse text-gray-700", className)}>
      {text}
      {dots}
    </div>
  );
};
