export const HelpPage = () => {
  return (
    <div className="rounded">
      <div className="lg:my-8 lg:mx-36 bg-white p-5 rounded">
        <h1 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl mb-3">
          Documentation
        </h1>
        <h2 className="scroll-m-20 border-b pb-2 text-2xl font-semibold tracking-tight first:mt-0 mb-4">
          Table of Contents
        </h2>
        <ul className="my-6 ml-10 [&>li]:mt-2">
          <li>
            <a
              href="#introduction"
              className="scroll-m-20 text-xl font-semibold tracking-tight text-sky-600"
            >
              I. &nbsp;&nbsp;&nbsp;Introduction
            </a>
          </li>
          <li>
            <a
              href="#getting-started-with-the-vastai"
              className="scroll-m-20 text-xl font-semibold tracking-tight text-sky-600"
            >
              II. &nbsp;&nbsp;Getting started with the VastAI
            </a>
          </li>
          <li>
            <a
              href="#how-to-use-vastai-chatgpt"
              className="scroll-m-20 text-xl font-semibold tracking-tight text-sky-600"
            >
              III. &nbsp;How to use VastAI ChatGPT?
            </a>
          </li>
          <li>
            <a
              href="#how-to-use-prompt-helper"
              className="scroll-m-20 text-xl font-semibold tracking-tight text-sky-600"
            >
              IV. &nbsp;How to use Prompt Helper?
            </a>
          </li>
          <li>
            <a
              href="#how-to-use-whisper"
              className="scroll-m-20 text-xl font-semibold tracking-tight text-sky-600"
            >
              V. &nbsp;&nbsp;How to use Whisper?
            </a>
          </li>
          <li>
            <a
              href="#how-to-deal-with-error-messages-or-a-failed-generation"
              className="scroll-m-20 text-xl font-semibold tracking-tight text-sky-600"
            >
              VI. &nbsp;How to deal with error messages or a failed generation
            </a>
          </li>
        </ul>

        <h2
          id="introduction"
          className="scroll-m-20 text-xl font-semibold tracking-tight mt-5"
        >
          Introduction
        </h2>
        <p className="leading-7 [&:not(:first-child)]:mt-3">
          We're on a mission to revolutionize to access to Azure OpenAI's latest
          and most powerful AI models, such as ChatGPT, DALL-E 2, and
          MidJourney.
        </p>
        <ul className="my-6 ml-6 list-disc [&>li]:mt-2">
          <li>Save time preparing for customer meetings points.</li>
          <li>Spark new ideas and discussions</li>
          <li>Provide product recommendations</li>
          <li>
            Generate creative content, such as marketing copy and product
            descriptions
          </li>
          <li>
            Write poems, stories, and other creative content for your blog
            posts.
          </li>
        </ul>

        <h3 className="scroll-m-20 text-xl font-semibold tracking-tight mt-5">
          ChatGPT
        </h3>
        <p className="leading-7 [&:not(:first-child)]:mt-3">
          ChatGPT can be used to generate blog post ideas, outline blog posts,
          and even write entire blog posts. For example, you could prompt
          ChatGPT to write a blog post on the topic of "How to use Azure OpenAI
          to create blog posts."
        </p>

        <h3 className="scroll-m-20 text-xl font-semibold tracking-tight mt-5">
          DALL-E 2
        </h3>
        <p className="leading-7 [&:not(:first-child)]:mt-3">
          DALL-E 2 can be used to generate images and other creative content
          that can be used to illustrate blog posts. For example, you could use
          DALL-E 2 to generate an image of a robot writing a blog post for your
          blog post on "How to use Azure OpenAI to create blog posts."
        </p>

        <h3 className="scroll-m-20 text-xl font-semibold tracking-tight mt-5">
          Midjourney
        </h3>
        <p className="leading-7 [&:not(:first-child)]:mt-3">
          Midjourney is another AI model that can be used to generate images and
          other creative content. Midjourney can be used to create more abstract
          and artistic images than DALL-E 2, which can be useful for creating
          blog posts with a more unique and visual appeal.
        </p>

        <h3 className="scroll-m-20 text-xl font-semibold tracking-tight mt-5">
          Whisper
        </h3>
        <p className="leading-7 [&:not(:first-child)]:mt-3">
          Whisper is a state-of-the-art automatic speech recognition (ASR)
          system that can transcribe audio in over 100 languages with high
          accuracy. It is trained on a massive dataset of multilingual and
          multitask supervised data collected from the web, and can transcribe
          speech audio into the text in the language it is spoken (ASR) as well
          as translated into English (speech translation).
        </p>
        <p className="leading-7 [&:not(:first-child)]:mt-3">
          Use the Azure OpenAI API to generate a blog post. For example, you
          could use ChatGPT to generate a blog post outline, and then use DALL-E
          2 to generate an image to illustrate the blog post.
        </p>

        <h2
          id="getting-started-with-the-vastai"
          className="scroll-m-20 text-xl font-semibold tracking-tight mt-5"
        >
          Getting started with the VastAI
        </h2>
        <p className="leading-7 [&:not(:first-child)]:mt-3">
          You need to make sure that you are logged in correctly
          ("authenticated") to the current platform (Vastai).
        </p>

        <h2
          id="how-to-use-vastai-chatgpt"
          className="scroll-m-20 text-xl font-semibold tracking-tight mt-5"
        >
          How to use VastAI ChatGPT?
        </h2>
        <p className="leading-7 [&:not(:first-child)]:mt-3">
          Just type your ideas or whatever you want ChatGPT to generate for you,
          Just mention it in "ChatGPT" screen and type it in the field and click
          "Submit". Before submitting make sure that (model = gpt-3.5-turbo) is
          selected. Once the "Submit" button is clicked, wait for few minutes to
          get the generative & creative response.
        </p>

        <h2
          id="how-to-use-prompt-helper"
          className="scroll-m-20 text-xl font-semibold tracking-tight mt-5"
        >
          How to use Prompt Helper?
        </h2>
        <p className="leading-7 [&:not(:first-child)]:mt-3">
          <strong className="font-bold">ChatGPT Prompt Helper:</strong> If You
          have no idea about the content or prompt. In the Prompt Helper, you
          have to type the content or keyword and click "Submit". Be Patient! It
          will generate some prompts for you. Be sure before selecting the
          prompts and it automatically gets filled in the "ChatGpt" and click
          "Submit" and wait for the creative and generative response.
        </p>
        <p className="leading-7 [&:not(:first-child)]:mt-3">
          <strong className="font-bold">Dalle Prompt Helper:</strong> If You
          have no idea about the image type or creativity. In the Prompt Helper,
          you have to type the content or keyword and click "Submit". Be
          Patient! It will generate some prompts for you. Be sure before
          selecting the prompts and it automatically gets filled in the
          "ChatGpt". To finetune and generate more than 1 image fill the input
          field with desired value and click "Submit" and wait for the creative
          and generative images. Note: Image generation will take some time.
        </p>
        <p className="leading-7 [&:not(:first-child)]:mt-3">
          <strong className="font-bold">MidJourney Prompt Helper:</strong> If
          You have no idea about the more artistic image. In the Prompt Helper,
          you have to type the content or keyword and click "Submit". Be
          Patient! It will generate some prompts for you. Be sure before
          selecting the prompts and it automatically gets filled in the
          "ChatGpt" and click "Submit" and wait for the creative and artistic
          image than Dalle. You can also Upscale the images generated by using
          the "Upscale" button below the generated image. Note: Image generation
          will take some time.
        </p>

        <h2
          id="how-to-use-whisper"
          className="scroll-m-20 text-xl font-semibold tracking-tight mt-5"
        >
          How to use Whisper?
        </h2>
        <p className="leading-7 [&:not(:first-child)]:mt-3">
          a. Drag and drop audio or video file to generate text file with speech
          recognition for transcription or translation. <br />
          b. Make sure of formats: mp3, mp4, mpeg, mpga, m4a, wav, or webm{" "}
          <br />
          c. Once you have uploaded the file click "Transcribe" for
          transcription and "Translate" for translation. <br />
          <span className="text-red-500 font-bold">
            Note: Not to upload any confidential or strictly confidential
            company data.
          </span>
        </p>

        <h2
          id="how-to-deal-with-error-messages-or-a-failed-generation"
          className="scroll-m-20 text-xl font-semibold tracking-tight mt-5"
        >
          How to deal with error messages or a failed generation
        </h2>
        <p className="leading-7 [&:not(:first-child)]:mt-3">
          Usually, something went wrong the permission or the
          authentication.Otherwise, Some one will reach you shortly. Please{" "}
          <a href="mailto:vastai@basf.com" className="font-bold text-sky-600">
            contact us on mail
          </a>{" "}
          to report the issue.
        </p>
      </div>
    </div>
  );
};
