import { FC } from "react";

type Props = {
  onSubmit: (prompt: string) => void;
  prompt: string;
};

export const PromptCard: FC<Props> = ({ onSubmit, prompt }) => {
  return (
    <div
      onClick={() => {
        onSubmit(prompt);
      }}
      className="rounded-lg bg-white mb-5 hover:shadow-md p-4 hover:cursor-pointer border hover:scale-[1.01] active:scale-100 duration-200"
    >
      {prompt}
    </div>
  );
};
